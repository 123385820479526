<template>
  <div
    class="tw-flex tw-min-h-[284px] tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-px-8 tw-pb-12 tw-pt-10"
  >
    <NuxtImg
      src="https://files.sproutly.africa/assets/images/icons/empty.svg"
      alt="empty"
      class="tw-h-12 tw-w-12 tw-text-center"
    />

    <div>
      <h3
        class="tw-text-center tw-text-base tw-font-semibold tw-capitalize tw-leading-normal tw-text-gray-900"
      >
        {{ title }}
      </h3>
      <p
        class="tw-max-w-[352px] tw-text-center tw-text-sm tw-font-normal tw-leading-tight tw-text-gray-500"
      >
        {{ description }}
      </p>
    </div>

    <AppButton v-if="buttonText.length" type="dark" @click.prevent="onClick()">
      <div class="tw-flex tw-items-center tw-space-x-2">
        <AppIcon name="plus" />
        <span>{{ buttonText }}</span>
      </div>
    </AppButton>
  </div>
</template>

<script setup lang="ts">
type EmptyPageType = {
  title: string;
  description: string;
  buttonText?: string;
  icon?: string;
};

const emit = defineEmits(["click"]);

const onClick = () => {
  emit("click");
};

const props = withDefaults(defineProps<EmptyPageType>(), {
  title: "title",
  description: "description",
  buttonText: "",
  icon: "material-symbols:add",
});
</script>

<style scoped></style>
